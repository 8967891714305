import { AppConfig } from "AppConfig";
import { getRequestHeaders } from "msal/MsalAuthProvider";
import { createLog } from "_data/services/extract/service";

export const getUsersRequest = (callback) => {
  fetch(`${AppConfig.SERVICE_BASE_URL}/api/v1/admin/user`, {
    method: "GET",
    headers: getRequestHeaders(),
  })
    .then((response) => response.json())
    .then((response) => {
      callback(true, response);
    })
    .catch((error) => {
      callback(false, null);
      createLog("Error", `Get list of users : ${error}`);
    });
};

export const getActivitiesRequest = (callback) => {
    fetch(`${AppConfig.SERVICE_BASE_URL}/api/v1/admin/activity`, {
      method: "GET",
      headers: getRequestHeaders(),
    })
      .then((response) => response.json())
      .then((response) => {
        callback(true, response);
      })
      .catch((error) => {
        callback(false, null);
        createLog("Error", `Get list of activities : ${error}`);
      });
  };