import { AppConfig } from "AppConfig";
import ReactGA from "react-ga4";

export const initGA = (measurementId) => {
  if (AppConfig.APP_GOOGLE_ANALYTICS_ENABLED) {
    ReactGA.initialize(measurementId);
  }
};

export const logPageView = (path) => {
  if (AppConfig.APP_GOOGLE_ANALYTICS_ENABLED) {
    ReactGA.send({ hitType: "pageview", page: path });
  }
};

export const logEvent = (category, action, label) => {
  if (AppConfig.APP_GOOGLE_ANALYTICS_ENABLED) {
    ReactGA.event({
      category: category,
      action: action,
      label: label,
    });
  }
};
