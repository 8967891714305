import { useEffect, useState } from "react";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import "__resources/styles/custom-toggle.css";
import usePayment from "_data/hooks/payment/usePayment";
import SubscriptionTabs from "./SubscriptionTabs";
import { AppConfig } from "AppConfig";

export default function SubscriptionCard({
  isYearSubscription = false,
  setIsYearSubscription = () => {},
  styles,
  license,
  price,
  description,
  unavailableItems = [],
  items,
  hasToggle = false,
  hasDiscount = false,
  isButtonDisabled = false,
  buttonText,

  onClick,
}) {
  const [isYear, setIsYear] = useState(isYearSubscription);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [subscriptionPrice, setSubscriptionPrice] = useState(price);

  const { getSubscriptionTypes } = usePayment();

  const getSubscriptionTypesData = async () => {
    await getSubscriptionTypes((success, response) => {
      if (success) {
        setSubscriptionData(response);
      }
    });
  };

  const changeSubscriptionDuration = (value) => {
    setIsYear(value);
    setIsYearSubscription(value);
  };

  useEffect(() => {
    setSubscriptionPrice(isYear ? "Year" : price);
    getSubscriptionTypesData();
  }, []);

  const openTerms = () => {
    window.open(
      AppConfig.APP_WEBSITE_HANDELSBETINGELSER,
      "_blank",
      "noopener,noreferrer"
    );
  };

  return (
    <div
      className={`flex flex-col justify-center w-1/3 p-3 tall:p-8 rounded-lg ${styles} ${
        license == "Premium" && hasDiscount && "border border-primaryBlue"
      } relative`}
    >
      <div
        className={`flex w-full justify-center items-center ${
          license == "Premium" && hasDiscount && "absolute -top-5 right-0 left-0"
        }`}
      >
        <div
          className={`flex justify-center items-center text-primaryBlue text-standardXSmall tall:text-[14px] font-latoBold bg-white px-4 py-2 ${
            license == "Premium" && hasDiscount &&
            "bg-lightOrange text-primaryBrown border border-primaryBrown"
          }`}
        >
          <p>{license == "Premium" && hasDiscount ? `Promo ${license}` : license}</p>
        </div>
      </div>

      <div
        className={`flex flex-col w-full ${
          license == "Premium" && hasDiscount ? "h-[47%]" : "h-2/5"
        } ${
          license == "Premium" && hasDiscount ? "tall:h-[42%]" : "tall:h-1/3"
        } items-center ${license == "Premium" && hasDiscount && "pt-3 tall:pt-1"}`}
      >
        {license == "Premium" && hasDiscount && (
          <div className="flex w-full justify-center text-center text-xs tall:text-sm text-textGray mb-2">
            <p>
              Kampagneprisen gælder til og d. 31. december 2024.
              <br />
              <span className="text-[11px] tall:text-[13px]">Læs de gældende betingelser </span>
              <span className="text-blue-600 text-[11px] tall:text-[13px] underline cursor-pointer" onClick={() => openTerms()}>her</span>.
            </p>
          </div>
        )}
        <SubscriptionTabs
          license={license}
          isYear={isYear}
          changeSubscriptionDuration={changeSubscriptionDuration}
        />

        {license == "Premium" && (
          <div className={`flex w-full ${hasDiscount ? "h-32 tall:h-28" : "h-12 tall:h-20"} justify-between items-center text-primaryBlue my-2 text-[22px] tall:text-[32px] tall:my-4 first-letter font-latoBold !leading-none`}>
            <div className="flex flex-col w-full justify-center items-center gap-3">
              <p className={`${isYear && "text-lightGrey"}`}>
                DKK{" "}
                {subscriptionData
                  ?.find((sub) => sub.type === "Month")
                  .baseAmount.toLocaleString("da-DK", {
                    minimumFractionDigits: 0,
                  })}
              </p>
              {hasDiscount && <p
                className={`text-lg line-through ${
                  isYear ? "text-lightGrey" : "text-primaryBrown"
                }`}
              >
                DKK 499
              </p>}
            </div>
            <div className="flex h-full w-[1px] bg-lightGrey"></div>
            <div className="flex flex-col w-full justify-center items-center gap-3">
              <p className={`${!isYear && "text-lightGrey"}`}>
                DKK{" "}
                {subscriptionData
                  ?.find((sub) => sub.type === "Year")
                  .baseAmount.toLocaleString("da-DK", {
                    minimumFractionDigits: 0,
                  })}
              </p>
              {hasDiscount && <p
                className={`text-lg line-through ${
                  !isYear ? "text-lightGrey" : "text-primaryBrown"
                }`}
              >
                DKK 4.990
              </p>}
            </div>
          </div>
        )}
        {license === "Premium" && (
          <div className="flex flex-row gap-x-3 justify-center items-center mt-[-12px]">
            <span className="text-standardXSmall tall:text-standardSmall font-latoBold text-primaryBlue">
              (Excl. VAT)
            </span>
          </div>
        )}
        <div className="flex w-full h-14 justify-center items-center text-[10px] tall:text-standardSmall text-center">
          <p>{description}</p>
        </div>
      </div>
      <div className="flex flex-col h-full tall:pt-2 tall:gap-y-3">
        {license !== "Freemium" && license !== "Premium" && (
          <p className="text-textGray text-standardXSmall tall:text-standardSmall">
            Alt fra {license === "Premium" ? "Freemium" : "Premium"} samt:
          </p>
        )}
        {unavailableItems &&
          unavailableItems.map((item, index) => (
            <div
              key={index}
              className="flex flex-row w-full justify-start items-center gap-x-2"
            >
              <img className="content-closeCircleIcon" alt="" />
              <p className="text-textGray text-standardXSmall tall:text-standardSmall">
                {item}
              </p>
            </div>
          ))}
        {items.map((item, index) => (
          <div
            key={index}
            className="flex flex-row w-full justify-start items-center gap-x-2"
          >
            <img className="content-checkCircleIcon" alt="" />
            <p className="text-textGray text-standardXSmall tall:text-standardSmall">
              {item}
            </p>
          </div>
        ))}
      </div>
      {buttonText && (
        <div
          className={`flex flex-col w-full h-fit bg-primaryBlue text-white
             p-2 cursor-pointer text-standardXSmall tall:text-standardSmall font-latoBold uppercase justify-center items-center  ${
               isButtonDisabled &&
               "bg-lightGrey text-textXLightGray pointer-events-none select-none"
             }`}
          onClick={onClick}
        >
          {buttonText}
        </div>
      )}
    </div>
  );
}
