import { useState } from "react";
import UserList from "./UserList";
import ActivityLog from "./ActivityLog";
import CheckBox from "__utilities/components/CheckBox";

export default function AdminView() {
  const [showActivityLog, setShowActivityLog] = useState(false);
  const [isDemoUsersIncluded, setIsDemoUsersIncluded] = useState(false);
  return (
    <div className="flex flex-col w-full h-full justify-start items-start gap-y-6 p-5 py-8 tall:p-11">
      <div className="flex flex-row w-full h-full justify-start gap-x-10 tall:gap-x-20 pb-14 tall:pb-10">
        <div className="flex flex-col h-full justify-between w-[4%] tall:w-[5%] gap-y-4">
          <div className="flex flex-col gap-2">
            <p
              onClick={() => setShowActivityLog(false)}
              className={`${
                !showActivityLog ? "text-primaryBlue" : "text-textXLightGray"
              } text-sm tall:text-lg font-latoBold cursor-pointer`}
            >
              Users
            </p>
            <p
              onClick={() => setShowActivityLog(true)}
              className={`${
                showActivityLog ? "text-primaryBlue" : "text-textXLightGray"
              } text-sm tall:text-lg font-latoBold cursor-pointer`}
            >
              Activities
            </p>
          </div>
          <div className="absolute top-2 tall:top-3 right-6 tall:right-12 text-xs tall:text-sm text-textGray">
            <CheckBox
              text={"Include demo users"}
              isChecked={isDemoUsersIncluded}
              onChange={() => setIsDemoUsersIncluded(!isDemoUsersIncluded)}
            />
          </div>
        </div>

        <div className="flex flex-col w-full h-full">
          <div className="flex flex-col w-full h-full">
            {!showActivityLog ? (
              <UserList isDemoUsersIncluded={isDemoUsersIncluded} />
            ) : (
              <ActivityLog isDemoUsersIncluded={isDemoUsersIncluded} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
