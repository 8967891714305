import {
  getActivitiesRequest,
  getUsersRequest,
} from "_data/services/admin/service";

export const useAdmin = () => {
  const getListUsersData = (callback = () => {}) => {
    getUsersRequest((success, response) => {
      callback(success, response);
    });
  };
  const getListActivitiesData = (callback = () => {}) => {
    getActivitiesRequest((success, response) => {
      callback(success, response);
    });
  };
  return { getListUsersData, getListActivitiesData };
};
