import { formatNumber } from "__utilities/functions/public";
import { useAdmin } from "_data/hooks/admin/useAdmin";
import { useEffect, useRef, useState } from "react";

export default function ActivityLog({isDemoUsersIncluded}) {
  const [activities, setActivities] = useState([]);
  const [cityCount, setCityCount] = useState(0);
  const [nationalCount, setNationalCount] = useState(0);
  const [supremeCount, setSupremeCount] = useState(0);
  const [totalNumberOfDocs, setTotalNumberOfDocs] = useState(0);
  const [totalRequestedPages, setTotalRequestedPages] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const { getListActivitiesData } = useAdmin();

  const getActivities = async (shouldFilter = true) => {
    await getListActivitiesData((success, activities) => {
      if (success) {
        if (shouldFilter) {
          let filteredActivities = activities.filter((a) => !a.isDemo);
          setActivities(filteredActivities);
          calculateTotals(filteredActivities);
        } else {
          setActivities(activities);
          calculateTotals(activities);
        }
        
      }
    });
  };

  useEffect(() => {
    getActivities(!isDemoUsersIncluded);
  }, [isDemoUsersIncluded]);

  const calculateTotals = (activities) => {
    let totalCity = activities.filter(
      (activity) => activity.extractType === "City"
    ).length;
    let totalNational = activities.filter(
      (activity) => activity.extractType === "National"
    ).length;
    let totalSupreme = activities.filter(
      (activity) => activity.extractType === "Supreme"
    ).length;

    let totalNoDocs = activities.reduce(
      (sum, activity) => sum + activity.numberOfDocs,
      0
    );
    let totalRequestedPages = activities.reduce(
      (sum, activity) => sum + activity.requestedPages,
      0
    );
    let totalPages = activities.reduce(
      (sum, activity) => sum + activity.totalPages,
      0
    );

    setCityCount(totalCity);
    setNationalCount(totalNational);
    setSupremeCount(totalSupreme);
    setTotalNumberOfDocs(totalNoDocs);
    setTotalRequestedPages(totalRequestedPages);
    setTotalPages(totalPages);
  };

  const ref = useRef();

  return (
    <div className="w-full h-full text-[10px] tall:text-sm text-primaryBlue">
      <div className="flex flew-row w-full items-center bg-[#F0F3F5] h-8 px-3 font-semibold">
        <div className="w-[15%]">Date</div>
        <div className="w-[18%]">Username</div>
        <div className="w-[15%] pl-1">Display name</div>
        <div className="w-[10%]">Court type</div>
        <div className="w-[8%]">Extract type</div>
        <div className="w-[7%] pr-5 text-right">No. docs</div>
        <div className="w-[7%] pr-5 text-right">Req. pages</div>
        <div className="w-[7%] pr-5 text-right">Total pages</div>
        <div className="w-[8%] pr-2 tall:pr-3 text-right">File size</div>
        <div className="w-[8%] pr-2 text-right">Status/Result</div>
      </div>
      <div ref={ref} className="w-full h-full relative ">
        <div className={`flex flex-col h-full w-full overflow-y-auto absolute`}>
          {activities?.map((activity, index) => (
            <div
              key={activity.id}
              className={`flex flex-row w-full items-center px-3 py-[5px] ${
                index % 2 === 1 && "bg-[#F6F5F3]"
              } hover:bg-slate-200 ${activity?.isDemo && 'text-textLightGray'}`}
            >
              <div className="w-[15%]">
                <p>{`${new Date(activity?.timestampCreated).toLocaleString(
                  "en-GB",
                  {
                    day: "numeric",
                    month: "numeric",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hourCycle: "h23",
                  }
                )} - ${new Date(activity?.timestampCompleted).toLocaleString(
                  "en-GB",
                  {
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                    hourCycle: "h23",
                  }
                )}`}</p>
              </div>
              <div className="w-[18%]">{activity?.username}</div>
              <div className="w-[15%] pl-2">{activity?.displayName}</div>
              <div className="w-[10%] pl-1">{activity?.extractType}</div>
              <div className="w-[8%] pl-1">
                {activity?.isEdit ? "Edit" : "New"}
              </div>
              <div className="w-[7%] pr-3 text-right">
                {activity?.numberOfDocs}
              </div>
              <div className="w-[7%] pr-3 text-right">
                {activity?.requestedPages}
              </div>
              <div className="w-[7%] pr-2 text-right">
                {activity?.totalPages}
              </div>
              <div className="w-[8%] text-right">{activity?.fileSizeText}</div>
              <div className="w-[8%] text-right">{activity?.status}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-row w-full justify-end items-center bg-[#F0F3F5] font-semibold text-[11px] tall:text-sm py-1">
        <div className="flex w-[15%] gap-3 justify-start items-center pr-3">
          <span className="w-full font-semibold pl-3">Total activities:</span>{" "}
          <div className="flex justify-end items-center tall:pr-2">
            {formatNumber(activities?.length)}
          </div>
        </div>
        <div className="w-[18%]"></div>
        <div className="w-[15%] "></div>
        <div className="w-[10%] flex justify-center">
          <div className="flex flex-row gap-1 w-2/3 items-center">
            <span title="City">{cityCount}</span>
            <span>/</span>
            <span title="National">{nationalCount}</span>
            <span>/</span>
            <span title="Supreme">{supremeCount}</span>
          </div>
        </div>
        <div className="w-[8%] "></div>
        <div className="flex w-[9%] justify-end text-right mr-[5px] tall:mr-2">
          <div className="flex w-1/2 justify-end items-center p-1">
            {formatNumber(totalNumberOfDocs)}
          </div>
        </div>
        <div className="flex w-[9%] justify-center text-right mr-2 tall:mr-[10px]">
          <div className="flex w-1/2 justify-end items-center p-1">
            {formatNumber(totalRequestedPages)}
          </div>
        </div>
        <div className="flex w-[9%] justify-start items-center mr-[14px] tall:mr-2 text-right">
          <div className="flex w-1/2 justify-end items-center p-1">
            {formatNumber(totalPages)}
          </div>
        </div>
        <div className="w-[13%]"></div>
      </div>
    </div>
  );
}
