import discount from "../../../../__resources/animations/price-tag.gif";

export default function SubscriptionButton({ discountActive = false, onClick }) {
  return (
    <div className="flex flex-row">
      {discountActive && <img
        src={discount}
        alt="Discount"
        className="h-8 w-8 top-0 left-0 translate-x-[14px] translate-y-[7px] z-10"
      />}
      <div
        className="flex flex-row justify-center items-center px-5 gap-x-2 cursor-pointer m-[10px] select-none bg-[#F0F3F5]  z-20"
        onClick={onClick}
      >
        <div className="flex w-7 h-7 tall:w-8 tall:h-8 justify-center items-center bg-primaryBlue">
          <img className="content-subscriptionIcon" alt="" />
        </div>
        <p className="font-latoBold text-[12px] tall:text-standardSmall text-primaryBlue">
          Opgradér nu
        </p>
      </div>
    </div>
  );
}
