import { formatNumber } from "__utilities/functions/public";
import { useAdmin } from "_data/hooks/admin/useAdmin";
import { useEffect, useRef, useState } from "react";

export default function UserList({ isDemoUsersIncluded }) {
  const [users, setUsers] = useState([]);
  const [totalUnique, setTotalUnique] = useState(0);
  const [totalLimited, setTotalLimited] = useState(0);
  const [totalGenerated, setTotalGenerated] = useState(0);
  const [activePremiumUsers, setActivePremiumUsers] = useState(0);
  const { getListUsersData } = useAdmin();

  const getUsers = async (shouldFilter = true) => {
    await getListUsersData((success, response) => {
      if (success) {
        if (shouldFilter) {
          let filteredUsers = response.filter((u) => !u.isDemo);
          setUsers(filteredUsers);
          calculateTotals(filteredUsers);
        } else {
          setUsers(response);
          calculateTotals(response);
        }
      }
    });
  };

  const licenseExpiringSoon = (date) => {
    const expirationDate = new Date(date);
    const currentDate = new Date();
    if (expirationDate > currentDate) {
      const timeDifference = expirationDate - currentDate;
      const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      return daysLeft <= 5 && daysLeft;
    }
  };

  const calculateTotals = (users) => {
    let totalNoDocs = users.reduce((sum, user) => sum + user.uniqueExtracts, 0);
    let totalRequestedPages = users.reduce(
      (sum, user) => sum + user.limitedExtracts,
      0
    );
    let totalPages = users.reduce(
      (sum, user) => sum + user.generatedExtracts,
      0
    );

    let activePremiumUsersCount = users.filter(
      (user) => user?.license === "Premium" && !user?.licenseHasExpired
    ).length;

    setTotalUnique(totalNoDocs);
    setTotalLimited(totalRequestedPages);
    setTotalGenerated(totalPages);
    setActivePremiumUsers(activePremiumUsersCount);
  };

  useEffect(() => {
    getUsers(!isDemoUsersIncluded);
  }, [isDemoUsersIncluded]);

  const ref = useRef();

  return (
    <div className="w-full h-full text-[11px] tall:text-sm text-primaryBlue ">
      <div className="flex flew-row w-full items-center bg-[#F0F3F5] h-8 px-3 font-semibold">
        <div className="w-[11%]">Signup date</div>
        <div className="w-[5%]">Provider</div>
        <div className="w-[20%]">Username</div>
        <div className="w-[18%]">Display name</div>
        <div className="w-[9%]">License type</div>
        <div className="w-[9%]">License status</div>
        <div className="w-[11%]">License expiration</div>
        <div
          title="Unique extracts"
          className="w-[10%] pr-2 tall:pr-3 text-right"
        >
          Unique extracts
        </div>
        <div
          title="Limited extracts"
          className="w-[10%] pr-2 tall:pr-3 text-right"
        >
          Limited extracts
        </div>
        <div title="Generated extracts" className="w-[10%] pr-2 text-right">
          Generated extracts
        </div>
      </div>
      <div ref={ref} className="w-full h-full relative ">
        <div className={`flex flex-col h-full w-full overflow-y-auto absolute`}>
          {users?.map((user, index) => (
            <div
              key={user.id}
              className={`flex flex-row w-full items-center px-3  py-[5px] ${
                index % 2 === 1 && "bg-[#F6F5F3]"
              } hover:bg-slate-200 ${user?.isDemo && 'text-textLightGray'}`}
            >
              <div className="w-[11%]">
                {new Date(user?.signupDate).toLocaleString("en-GB", {
                  day: "numeric",
                  month: "numeric",
                  year: "numeric",
                  hour: "2-digit",
                  minute: "2-digit",
                  second: "2-digit",
                  hourCycle: "h23",
                })}
              </div>
              <div className="flex w-[5%] pr-2 tall:pr-3 justify-center">
                {user?.provider === "MicrosoftAccount" && (
                  <img
                    className="content-microsoftIcon opacity-50 h-4 w-4 tall:h-5 tall:w-5"
                    alt="Microsoft account"
                  />
                )}
              </div>
              <div className="w-[20%]">{user?.username}</div>
              <div className="w-[18%]">{user?.displayName}</div>
              <div className="w-[9%] pl-1 tall:pl-0">{user?.license}</div>
              <div className="w-[9%] pl-1 tall:pl-0">
                <p>
                  {user?.license !== "Freemium" && user?.licenseHasExpired
                    ? "Expired"
                    : "Active"}
                </p>
              </div>
              <div
                className={`w-[11%] pl-1 ${
                  licenseExpiringSoon(user?.licenseExpiration) && "text-[#C58B68]"
                }`}
              >
                {user?.licenseExpiration
                  ? new Date(user?.licenseExpiration).toLocaleString("en-GB", {
                      day: "numeric",
                      month: "numeric",
                      year: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                      second: "2-digit",
                      hourCycle: "h23",
                    })
                  : ""}
              </div>
              <div
                className={`w-[10%] ${users?.length < 16 && "pr-2"} ${
                  users?.length < 25 ? "tall:pr-3" : "tall:pr-1"
                } text-right`}
              >
                {user?.uniqueExtracts}
              </div>
              <div
                className={`w-[10%] ${users?.length < 16 && "pr-2"} ${
                  users?.length < 25 ? "tall:pr-3" : "tall:pr-1"
                } text-right`}
              >
                {user?.limitedExtracts}
              </div>
              <div
                className={`w-[10%] ${users?.length < 16 && "pr-2"} ${
                  users?.length < 25 && "tall:pr-2"
                } text-right`}
              >
                {user?.generatedExtracts}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flex flex-row w-full justify-end items-center bg-[#F0F3F5] font-semibold text-[11px] tall:text-sm py-1">
        <div className="flex w-[11%] gap-3 justify-start items-center pr-3">
          <span className="w-full font-semibold pl-3">Total users:</span>{" "}
          <div className="flex justify-end items-center">
            {formatNumber(users?.length)}
          </div>
        </div>
        <div className="flex w-[5%] justify-center"></div>
        <div className="w-[20%]"></div>
        <div className="w-[18%]"></div>
        <div className={`flex w-[9%] justify-start items-center  text-right`}>
          <div
            className="flex w-full justify-start items-center p-1 pr-2 tall:pr-2"
            title="Active premium accounts"
          >
            {`${formatNumber(activePremiumUsers)} (${Math.round(((activePremiumUsers / users.length) * 100))}%)`}
          </div>
        </div>
        <div className="w-[9%]">
          <p></p>
        </div>
        <div className="flex w-[11%]"></div>
        <div className={`flex w-[10%] justify-end items-center  text-right`}>
          <div className="flex w-1/2 justify-end items-center p-1">
            {formatNumber(totalUnique)}
          </div>
        </div>
        <div className={`flex w-[10%] justify-end items-center  text-right`}>
          <div className="flex w-1/2 justify-end items-center p-1">
            {formatNumber(totalLimited)}
          </div>
        </div>
        <div
          className={`flex w-[10%] justify-end items-center mr-4 tall:mr-5 text-right`}
        >
          <div className="flex w-1/2 justify-end items-center p-1">
            {formatNumber(totalGenerated)}
          </div>
        </div>
      </div>
    </div>
  );
}
